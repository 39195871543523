<script setup>
definePageMeta({
  name: "error",
});

const error = useError();

const { assistantId, ASSISTANT_ID_PARAM } = useAssistantId();

const goHome = () => {
  document.location.href = "/?" + ASSISTANT_ID_PARAM + "=" + assistantId.value;
};
</script>

<template>
  <div class="error">
    <div class="content">
      <template v-if="error.statusCode === 404">
        <h1>404</h1>
        <h2>Niet gevonden</h2>
        <p>Sorry, deze pagina lijkt niet (meer) te bestaan niet.</p>
      </template>
      <template v-else>
        <h1>{{ error.statusCode }}</h1>
        <h2>Foutje...</h2>
        <p>Sorry, er is iets mis te zijn gegaan:</p>
        <p class="message">"{{ error.message }}"</p>
      </template>
      <p>
        <button class="button" @click="goHome">Home</button>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  background-color: #fff7ee;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
  gap: 1rem;
  text-align: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  h1 {
    font-size: 5rem;
    margin: 0;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .message {
    font-style: italic;
  }

  .button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff8cdf;
    color: #000000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;

    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
}

// .error {
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   height: 100dvh;
//   gap: 1rem;
//   text-align: center;
//   overflow: auto;
//   -webkit-overflow-scrolling: touch;
// }

// .content {
//   display: flex;
//   flex-grow: 1;
//   flex-direction: column;
//   justify-content: center;
//   padding: 20px;
// }

// p {
//   margin-bottom: 20px;
// }
</style>
