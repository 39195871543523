import { default as chatkuyPNkCCYeMeta } from "/home/ploi/web.wijsneus.app/pages/chat.vue?macro=true";
import { default as forgot_45passwordEUbFohJsInMeta } from "/home/ploi/web.wijsneus.app/pages/forgot-password.vue?macro=true";
import { default as indexp1JxYIqyQwMeta } from "/home/ploi/web.wijsneus.app/pages/index.vue?macro=true";
import { default as loginkwO6tK3GGOMeta } from "/home/ploi/web.wijsneus.app/pages/login.vue?macro=true";
import { default as _91token_93sUXHwAEC57Meta } from "/home/ploi/web.wijsneus.app/pages/password-reset/[token].vue?macro=true";
import { default as privacyJiMQ2FqSVvMeta } from "/home/ploi/web.wijsneus.app/pages/privacy.vue?macro=true";
import { default as registerSBG8PlJMFbMeta } from "/home/ploi/web.wijsneus.app/pages/register.vue?macro=true";
import { default as contactcQqZ0H6U46Meta } from "/home/ploi/web.wijsneus.app/pages/support/contact.vue?macro=true";
import { default as faqIr2le6XkZJMeta } from "/home/ploi/web.wijsneus.app/pages/support/faq.vue?macro=true";
import { default as indexBqZSkDNg0HMeta } from "/home/ploi/web.wijsneus.app/pages/support/index.vue?macro=true";
import { default as verifyKvkRwGgCopMeta } from "/home/ploi/web.wijsneus.app/pages/verify.vue?macro=true";
import { default as welcomeLVEmLbpgG9Meta } from "/home/ploi/web.wijsneus.app/pages/welcome.vue?macro=true";
export default [
  {
    name: "chat",
    path: "/chat",
    meta: chatkuyPNkCCYeMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/chat.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordEUbFohJsInMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/forgot-password.vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexp1JxYIqyQwMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginkwO6tK3GGOMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/login.vue")
  },
  {
    name: "verify.token",
    path: "/password-reset/:token()",
    meta: _91token_93sUXHwAEC57Meta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/password-reset/[token].vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyJiMQ2FqSVvMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/privacy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerSBG8PlJMFbMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/register.vue")
  },
  {
    name: "contact",
    path: "/support/contact",
    meta: contactcQqZ0H6U46Meta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/support/contact.vue")
  },
  {
    name: "faq",
    path: "/support/faq",
    meta: faqIr2le6XkZJMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/support/faq.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexBqZSkDNg0HMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/support/index.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyKvkRwGgCopMeta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/verify.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeLVEmLbpgG9Meta || {},
    component: () => import("/home/ploi/web.wijsneus.app/pages/welcome.vue")
  }
]