import validate from "/home/ploi/web.wijsneus.app/node_modules/nuxt/dist/pages/runtime/validate.js";
import persist_45assistant_45id_45global from "/home/ploi/web.wijsneus.app/middleware/persistAssistantId.global.ts";
import persist_45settings_45id_45global from "/home/ploi/web.wijsneus.app/middleware/persistSettingsId.global.ts";
import welcome_45global from "/home/ploi/web.wijsneus.app/middleware/welcome.global.ts";
import manifest_45route_45rule from "/home/ploi/web.wijsneus.app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  persist_45assistant_45id_45global,
  persist_45settings_45id_45global,
  welcome_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "registration-enabled": () => import("/home/ploi/web.wijsneus.app/middleware/registrationEnabled.ts"),
  "sanctum:unverified": () => import("/home/ploi/web.wijsneus.app/middleware/sanctum:unverified.ts"),
  "sanctum:verified": () => import("/home/ploi/web.wijsneus.app/middleware/sanctum:verified.ts"),
  "sanctum:auth": () => import("/home/ploi/web.wijsneus.app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/home/ploi/web.wijsneus.app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}