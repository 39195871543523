import { useAssistantId } from "@/composables/useAssistantId";

export default defineNuxtRouteMiddleware(async (to) => {
  const { assistantId, ASSISTANT_ID_PARAM } = useAssistantId();

  if (assistantId.value && !to.query[ASSISTANT_ID_PARAM]) {
    return navigateTo({
      path: to.path,
      query: { ...to.query, [ASSISTANT_ID_PARAM]: assistantId.value },
    });
  }
});
