import { ref } from "vue";

const ASSISTANT_ID_PARAM = "aid";

const assistantId = ref();

export const useAssistantId = () => {
  return {
    ASSISTANT_ID_PARAM,
    assistantId,
  };
};
