import { Keys, usePreferences } from "@/composables/usePreferences";

export default defineNuxtRouteMiddleware(async (from) => {
  const { getPreferences } = usePreferences();

  const welcomeCompleted = await getPreferences({ key: Keys.welcomeCompleted });

  if (!welcomeCompleted.value && from.name !== "welcome") {
    return navigateTo("/welcome");
  }

  return;
});
